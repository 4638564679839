<template>
  <div
    v-if="cartHasItems"
    class="v-summary-card v-mb-sm"
    data-test-id="summary-card"
  >
    <div
      class="v-total-summary-single-summary-type"
      v-html="translate('totalSummaryPage.emptyCart')"
    />
  </div>
  <div
    v-else
    class="v-summary-card v-mb-sm"
    data-test-id="summary-card"
  >
    <div class="v-total-summary-single-summary-type">
      <span
        class="v-mr-xs"
        v-html="translate('totalSummaryPage.totalPay')"
      />
      <span>
        <common-currency
          :amount="total"
          data-test-id="order-total-price"
        />
      </span>
    </div>
    <transition
      v-if="showSumNeededForOrder"
      appear
      mode="out-in"
      name="fade"
    >
      <div
        v-if="
          minSumForDelivery > 0 &&
          minSumForDeliveryModified -
            (clientStore.ClientState.data?.FinalCalculatePriceConsider ?? 0) >
            0
        "
        class="v-summary-helper v-error-color v-d-flex v-flex-row"
        data-test-id="summary-helper"
      >
        <span v-html="translate('totalSummaryPage.totalHelperLeft')" />
        <common-currency
          class="v-d-inline v-ml-xxs"
          v-if="minSumForDeliveryModified !== minSumForDelivery"
          strikethrough
          :amount="
            minSumForDelivery -
            (clientStore.ClientState.data?.FinalCalculatePriceConsider ?? 0)
          "
        />
        <common-currency
          class="v-d-inline v-mr-xxs"
          :amount="
            minSumForDeliveryModified -
            (clientStore.ClientState.data?.FinalCalculatePriceConsider ?? 0)
          "
        />
        <span v-html="translate('totalSummaryPage.totalHelperCanOrder')" />
      </div>
    </transition>
    <transition
      v-if="
        showSumNeededForOrder &&
        !clientStore.ClientState.data?.DeliveryPaymentInfo?.FreeDelivery
      "
      appear
      mode="out-in"
      name="fade"
    >
      <div
        v-if="
          (clientStore.ClientState.data?.DeliveryPaymentInfo.NeedForFreeDelivery ??
            0) > 0
        "
        class="v-free-delivery v-d-flex v-flex-row"
      >
        <span v-html="translate('totalSummaryPage.totalHelperLeft')" />&nbsp;
        <common-currency
          class="v-d-inline v-ml-xxs v-mr-xxs"
          :amount="
            clientStore.ClientState.data?.DeliveryPaymentInfo.NeedForFreeDelivery
          "
        />&nbsp;
        <span v-html="translate('totalSummaryPage.totalHelperFree')" />
      </div>
    </transition>
    <arora-summary-details
      in-element
      :open="appConfig.VueSettingsPreRun.TotalSummaryOpenByDefault"
      :title="translate('totalSummaryPage.summaryOpen')"
    >
      <ul>
        <cart-entwash-total-summary-single
          :amount="amount"
          :text="translate('totalSummaryPage.cartSum')"
        />
        <cart-entwash-total-summary-single
          v-if="appConfig.RestaurantSettingsPreRun.ServiceFeeEnabled"
          :amount="serviceFee"
          :text="translate('totalSummaryPage.serviceFee')"
        />
        <cart-entwash-total-summary-single
          v-if="
            (appConfig.RestaurantSettingsPreRun.PriorityServiceDeliveryEnabled &&
              clientStore.courierDelivery) ||
            (appConfig.RestaurantSettingsPreRun.PriorityServicePickupEnabled &&
              clientStore.selfService)
          "
          :amount="priorityServiceCost"
          :text="translate('totalSummaryPage.priorityCooking')"
        />
        <cart-entwash-total-summary-single
          v-if="
            deliveryCost > 0 ||
            clientStore.ClientState.data?.DeliveryPaymentInfo?.FreeDelivery
          "
          :negate-cost="
            deliveryCost > 0 &&
            (clientStore.ClientState.data?.DeliveryPaymentInfo?.FreeDelivery ||
              deliveryCostModified !== deliveryCost)
          "
          :amount="deliveryCost"
          :amount-modified="deliveryCostModified"
          :text="translate('totalSummaryPage.delivery')"
          sign="+"
        />
        <cart-entwash-total-summary-single
          v-if="discount > 0"
          :amount="discount"
          :text="translate('totalSummaryPage.discount')"
          sign="-"
        />
        <cart-entwash-total-summary-single
          v-if="tax > 0"
          :amount="tax"
          :text="translate('totalSummaryPage.tax')"
          sign="+"
        />
        <cart-entwash-total-summary-single
          v-if="tip > 0"
          :amount="tip"
          :text="translate('totalSummaryPage.tip')"
          sign="+"
        />

        <cart-entwash-total-summary-single
          v-if="
            appConfig.RestaurantSettingsPreRun.BonusSystemEnabled &&
            accountStore.isLoggedIn
          "
          bonus-withdraw
          :amount="pointsWithdraw"
          :text="translate('totalSummaryPage.bonusWithdraw')"
          sign="-"
        />
        <cart-entwash-total-summary-single
          v-if="
            appConfig.RestaurantSettingsPreRun.BonusSystemEnabled &&
            accountStore.isLoggedIn
          "
          bonus-accrual
          :amount="pointsAccrual"
          :text="translate('totalSummaryPage.bonusAccrual')"
          sign="+"
        />
      </ul>
    </arora-summary-details>
    <span
      v-if="
        appConfig.RestaurantSettingsPreRun.BonusSystemEnabled &&
        !accountStore.isLoggedIn
      "
    >
      <span
        class="v-mr-xxs"
        v-html="translate('totalSummaryPage.notLoggedInText')"
      />
      <span
        class="v-summary-login"
        @click="handleShowLoginPopup"
        v-html="translate('totalSummaryPage.notLoggedInLink')"
      />
    </span>

    <cart-entwash-total-summary-partial-pay
      v-if="accountStore.isLoggedIn && showPartialPay"
    />
  </div>
</template>

<script setup lang="ts">
defineProps<{
  cartHasItems?: boolean
}>()

const { translate } = useI18nSanitized()
const accountStore = useAccountStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()
const { pathname } = useUrl()
const appConfig = useAppConfig()

const minSumForDelivery = computed<number>(
  () => clientStore.ClientState.data?.DeliveryPaymentInfo?.MinSum ?? 0
)
const minSumForDeliveryModified = computed<number>(
  () => clientStore.ClientState.data?.DeliveryPaymentInfo?.ModifiedMinSum ?? 0
)
const showSumNeededForOrder = computed<boolean>(
  () => appConfig.VueSettingsPreRun.TotalSummaryShowSumNeededForOrder
)
const showPartialPay = computed<boolean>(
  () => appConfig.VueSettingsPreRun.Links.CartSecondStep !== pathname
)
const serviceFee = computed<number>(() => {
  return clientStore.ClientState?.data?.ServiceFee?.Cost ?? 0
})
const priorityServiceCost = computed<number>(() => {
  return clientStore.ClientState?.data?.PriorityService?.Cost ?? 0
})
const tax = computed<number>(() => {
  return clientStore.ClientState?.data?.Cart?.ToPayWithMoneyActive?.Tax ?? 0
})
const tip = computed<number>(() => {
  return clientStore.ClientState?.data?.StateOrderData?.Gratuity ?? 0
})
const total = computed<number>(
  () => clientStore.ClientState.data?.FinalCalculatePrice ?? 0
)
const deliveryCost = computed<number>(
  () => clientStore.ClientState.data?.DeliveryPaymentInfo?.DeliveryCost ?? 0
)
const deliveryCostModified = computed<number>(
  () => clientStore.ClientState.data?.DeliveryPaymentInfo?.ModifiedDeliveryCost ?? 0
)
const withDiscount = computed<number>(() => {
  return clientStore.ClientState?.data?.Cart?.ToPayWithMoneyActive?.WithDiscount ?? 0
})
const discount = computed<number>(() => {
  return amount.value - withDiscount.value
})
const amount = computed<number>(() => {
  return clientStore.ClientState?.data?.Cart?.ToPayWithMoneyActive?.Amount ?? 0
})
const pointsAccrual = computed<number>(() => {
  return clientStore.ClientState?.data?.Cart?.PointsAccrual ?? 0
})
const pointsWithdraw = computed<number>(() => {
  const paidWithPoints =
    clientStore.ClientState?.data?.Cart?.ToPayWithPoints?.Amount ?? 0
  const partialPayPoints =
    clientStore.ClientState?.data?.Cart?.ToPayWithPartialPayPoints?.Amount ?? 0

  return paidWithPoints + partialPayPoints
})

const handleShowLoginPopup = async (): Promise<void> => {
  await popupStore.openPopup({
    popupClosable: appConfig.VueSettingsPreRun.LoginShowCloseButton,
    popupName: 'accountManagementPopup'
  })
}
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-summary-card {
  padding: 60px;
  background: variables.$Background;
  border-radius: variables.$BorderRadius;
  font-size: variables.$TextSizeMain;
  box-shadow: variables.$CardShadow;
  flex-grow: 1;
  height: 100%;

  .v-details-block {
    @include mixins.trans;
    border: none;

    summary {
      border: none;
      padding: 5px 0;
    }

    .summary-chevron-up,
    .summary-chevron-down {
      top: 0;
      right: 0;
    }

    .v-summary-content {
      cursor: default;
      padding: 0;
      font-weight: 400;
      line-height: 1.5;
      position: relative;
      top: 100%;
      left: 0;
      right: 0;
      background: transparent;
      z-index: 5;
      border: none;

      ul {
        padding: 0;
        margin: 0;
      }
    }
  }

  .v-summary-login {
    text-decoration: underline;
    cursor: pointer;
  }
}

.v-summary-total {
  font-size: 26px;
}

.v-free-delivery {
  font-size: variables.$TextSizeMain;
}

.v-summary-helper {
  font-size: variables.$TextSizeMain;

  .v-currency-wrapper {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@include mixins.sm {
  .v-summary-card {
    padding: 20px;
  }
}
</style>
